.contact__container {
    width: 58%;
    display: grid;
    grid-template-columns: 30% 50%;
    gap: 12%;

}

.contact__options {
    display: flex;
    flex-direction: column;
    gap: 2rem;

}

.contact__social {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 2px solid transparent;
    transition: var(--transition);
}

.contact__social:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
}

.icon {
    font-size: 2rem;
    margin-bottom: 0.5rem;
}

form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input,
textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 1rem;
    background: transparent;
    border: 2px solid var(--color-primary-variant);
    resize: none;
    color: #fff;
}

form button {
    animation: shadow-drop-top-bottom 0.4s linear both
}

@keyframes shadow-drop-top-bottom {
    0% {
        box-shadow: 0 0 0 0 transparent, 0 0 0 0 transparent
    }

    100% {
        box-shadow: 0px -12px 20px -12px rgba(124, 206, 244, 0.55), 0 12px 20px -12px rgba(150, 211, 248, 0.55)
    }
}

@media screen and (max-width:1024px) {
    .contact__container {
        grid-template-columns: 1fr;
        gap: 1rem;
        width: 70%;
        gap: 4rem;
    }

    form {
        padding: 0.2rem;
    }
}