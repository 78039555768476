.container__footer{
    text-align: center;
    margin-bottom: 3rem;
    color: var(--color-primary);
}
.right{
    margin-bottom: 5rem;
    text-align: center;

}
