.project__container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3rem;
}

.project__item {
    background: var(--color-bg-variant);
    padding: 1.3rem;
    border-radius: 5px 7px 5rem 4rem;
    border: 2px solid transparent;
    transition: var(--transition);
    width: 80%;
    height: 45%;

}

.project__item:hover {
    border-color: var(--color-primary-varaint);
    background: transparent;

}



@keyframes rotate-vertical-center {
    0% {
        transform: rotateY(0)
    }

    100% {
        transform: rotateY(360deg)
    }
}

article img {
    border-radius: 2.3rem;
    overflow: hidden;
    /* margin-top: -1rem; */

}

.project-img:hover {
    animation: rotate-vertical-center 1s infinite;
    
}

article h3 {
    margin: 1.2rem 0.2rem;
    text-align: center;
}
.project__item h5{
    text-align: center;
    opacity: 0.5;
}

.project-code-icon {
    margin: 1rem 0.5rem 0 0.5rem;
    display: grid;
    gap: 9rem;
    grid-template-columns: 1fr 1fr;
    margin-bottom: -2rem;
}

.bored{
    margin-top: 3rem;
}

.project-code-icon a {
    font-size: 2.5rem;
}





@media screen and (max-width:1024px) {
    .project__container{
        grid-template-columns: 1fr 1fr;
        gap: 0;
    }
    .project__item{
        margin: 1rem;
    }
}
@media screen and (max-width:600px) {
    .project__container{
        grid-template-columns: 1fr;
        gap: 0;
    }
    .project__item{
        margin: 1.5rem;
    }
}