.about__container {
    display: grid;
    grid-template-columns: 35% 50%;
    gap: 15%;
}

.about__me {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(45deg, transparent, var(--color-primary));
    display: grid;
    place-items: center;

}

.about__me img {
    border-radius: 2rem;
    transform: rotate(10deg);
    overflow: hidden;
    transition: 400ms;
}

.about__me img:hover {
    transform: rotate(0);


}

.about__content p {
    margin-top: 1rem;
}

.about__content a {
    margin-top: 5rem;
    animation: shadow-drop-top-bottom 0.4s linear both
}


@keyframes shadow-drop-top-bottom {
    0% {
        box-shadow: 0 0 0 0 transparent, 0 0 0 0 transparent
    }

    100% {
        box-shadow: 0px -12px 20px -12px rgba(124, 206, 244, 0.55), 0 12px 20px -12px rgba(150, 211, 248, 0.55)
    }
}

@media screen and (max-width:1024px) {
    .about__container {
        grid-template-columns: 1fr;
        gap: 0;
    }

    .about__me {
        width: 50%;
        margin: 2rem auto 4rem;

    }

    .about__content a {
        margin-top: 23px;

    }
}

@media screen and (max-width:600px) {
    .about__me {
        width: 70%;
        margin: 0 auto 4rem;

    }

    .about__content a {
        margin-top: 23px;

    }
}